import spells from './assets/spells.jpg'
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="witch-header">
                <img className="witch-logo" src={spells} alt="Logo"/>
                <h1 className="title">Witch Syndicate</h1>
                A band in Minneapolis
            </header>
            <div className="status-message">
            <p>Site coming soon</p>
            </div>
            <div className="socials">
                <p>Until then, visit us at:</p>
                <ul>
                    <li><a href="https://witchsyndicate.bandcamp.com">Bandcamp</a></li>
                    <li><a href="https://www.facebook.com/witchsyndicate">Facebook</a></li>
                    <li><a href="https://wwwyoutube.com/@WitchSyndicateBand">Youtube</a></li>
                    <li><a href="https://www.instagram.com/witchsyndicate">Instagram</a></li>
                </ul>
            </div>
        </div>
    );
}

export default App;
